<template>
  <div class="ov-dh-r1">
    <div class="box" v-if="detail.label">
      <div class="title-box">
        <span class="title">{{ detail.label }}</span>
        <span class="subTitle">（{{ detail.lNodes[0].label }}）</span>
      </div>
      <div class="node-wrapper">
        <div
          class="node-box"
          v-for="(item, index) in detail.rNodes"
          :key="index"
        >
          <span class="node-title">{{ item.label }}</span>
          <span
            class="node-des"
            v-for="(node, index) in item.subTitles"
            :key="index"
          >
            {{ node }}
          </span>
          <div
            class="node-img"
            :style="{ height: detail.label == '崛起繁荣' ? '300px' : '370px' }"
            v-if="item.img"
          >
            <!-- <img :src="item.img" alt="" /> -->
            <el-image
              style="width: 100%; height: 100%"
              :preview-src-list="[item.img]"
              :lazy="true"
              :src="item.img"
              fit="fill"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: {}
    }
  },
  mounted() {
    this.$eventBus.$off('ov-dh-node')
    this.$eventBus.$on('ov-dh-node', item => {
      console.log(item)
      this.detail = item
    })
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.ov-dh-r1 {
  height: 100%;
  width: 100%;
}
.box {
  @include flex(column);
  height: calc(100% - 60px);
  width: calc(100% - 80px);
  background: url('/images/components/ov/dh/r1-boder.png') no-repeat;
  background-size: 100% 100%;
  padding: 30px 40px;
  flex: 1;
  span {
    letter-spacing: 2px;
  }
}
.node-wrapper {
  @include flex(column);
  justify-content: space-between;
  flex: 1;
}
.title-box {
  @include flex();
  align-items: baseline;
  margin-bottom: 10px;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .subTitle {
    font-size: 20px;
  }
}

.node-box {
  @include flex(column);
  .node-img {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .node-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .node-des {
    margin-bottom: 10px;
  }
}
</style>
