<template>
  <div class="container">
    <r1></r1>
  </div>
</template>

<script>
import r1 from './components/r1'

export default {
  components: {
    r1
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex();
}
</style>
