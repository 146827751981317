<template>
  <ek-panel title="发展历程">
    <div class="box">
      <div class="node-box" v-for="(item, index) in list" :key="index">
        <div
          class="node-icon"
          :class="currIndex == index ? 'active-icon' : ''"
        ></div>
        <div class="text-box" @click="handleNode(index)">
          <span
            class="title"
            :class="currIndex == index ? 'active-title' : ''"
            >{{ item.label }}</span
          >
          <div class="t-n-box">
            <span
              class="node"
              :class="currIndex == index && node.select ? 'active-node' : ''"
              :style="{ 'font-weight': node.select ? 'bold' : '' }"
              v-for="(node, i) in item.lNodes"
              :key="i"
              >• {{ node.label }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      currIndex: 0,
      list: [
        {
          label: '萌芽',
          lNodes: [
            { label: '德占1898-1910年', select: true },
            {
              label: '形成45度棋盘街的城市形态，中心广场的集市是商业萌芽的基础'
            },
            { label: '日耳曼啤酒青岛分公司成立（青岛啤酒）' },
            { label: '清溪庵香火鼎盛' }
          ],
          rNodes: [
            {
              title: '45度棋盘街',
              subTitles: [
                '1902 的霍乱改变台东镇的建设思路，形成45度棋盘街的城市形态，中心广场的集市是商业萌芽的基础'
              ],
              img: '/images/components/ov/dh/1-1.png'
            },
            {
              title: '中心广场市集',
              subTitles: ['1902 年后，台东镇形成后的中心广场市集'],
              img: '/images/components/ov/dh/1-2.png'
            },
            {
              title: '青岛啤酒厂创立',
              subTitles: [
                '1903 年，德国人在米勒上尉街(今登州路)设立“日尔曼啤酒公司青岛股份公司”，抗日战争胜利后由青岛市政府当局派员接管，更名为“青岛啤酒公司”。'
              ],
              img: ''
            }
          ],
          heats: [],
          markers: [],
          aside: '从德战时期劳工居住区的中心广场上开始萌芽'
        },
        {
          label: '崛起繁荣',
          lNodes: [
            { label: '第一次日占1911-1922', select: true },
            { label: '1917年铃木丝厂创立（青岛丝织厂）' },
            {
              label: '商业已经由街入室；说书卖艺等文艺类商业活动出现'
            },
            {
              label: '台东发展为城市边缘的商业区'
            },
            {
              label: '铃木丝厂创立（青岛丝织厂）'
            },
            { label: '国民政府1922-1938', select: true },
            { label: '街巷内兴起各色街铺；出现多家大的商业市场' },
            { label: '同乐茶园、永昌书局等老字号开始出现' }
          ],
          rNodes: [
            {
              title: '铃木丝厂创立（青岛丝织厂）',
              subTitles: [
                '1917 年，铃木丝厂在台东创立，是山东丝织总厂的前身，先后改名为“山东丝织总厂”、“山东第一丝织厂”，后改为“国营青岛丝织厂”，21 世纪在利用青岛印染厂和青岛丝织厂厂址建了青岛天幕城'
              ],
              img: '/images/components/ov/dh/2-1.png'
            },
            {
              title: '礼拜集（旧货市场）',
              subTitles: [
                '1930 年，礼拜集出现，一直到上世纪 60 年代这个集 才取消。'
              ],
              img: '/images/components/ov/dh/2-2.png'
            },
            {
              title: '20世纪30年代，青岛台东工业区逐渐形成。',
              subTitles: [
                '1931 年 在利津路 8 号筹建了冀鲁制针厂。开启中国制针业。',
                '1934 年，华北第一家机器印染厂阳本印染在台东创建因建厂资本来源于“沈阳太阳卷烟厂”，故取名“阳本印染厂”。 后成为青岛第一印染厂。',
                '1934 年，东兴市场形成，青岛最早的五金材料市场之一',
                '1935 年，青岛最早的造纸厂在台东设立——太湖造纸厂',
                '1936 年，“一大五金行”在台东创立，后发展成为老字 号“台东五金”'
              ],
              img: ''
            }
          ],
          heats: [],
          markers: [],
          aside:
            '在日本第一次占领时期获得初步发展，经历了上世纪二三十年代的黄金时期，规模不断扩大，商业日趋成熟'
        },
        {
          label: '商业副中心',
          lNodes: [
            { label: '第二次日占1938-1945', select: true },
            {
              label:
                '台东被划为市区中心，商业进一步繁荣，成为仅次于中山路的商业中心'
            },
            { label: '解放后1945-1978', select: true },
            { label: '商业发展停滞不前' },
            { label: '礼拜集（旧货市场）成为时代产物' }
          ],
          rNodes: [
            {
              title: '大陆茶庄',
              subTitles: [
                '1941年，台东老字号“大陆茶庄”开业。大陆茶庄有三个，长兴路 9 号48 为总号，延安路 56 号为西号，宁夏路 126 号为东号。'
              ],
              img: '/images/components/ov/dh/3-1.png'
            },
            {
              title: '同泰橡胶厂',
              subTitles: ['1945 年，青岛同泰橡胶厂设立'],
              img: '/images/components/ov/dh/3-2.png'
            },
            {
              title: '聚福楼东记',
              subTitles: [
                '1947 年，青岛四大饭馆聚福楼东记于台东二路 30 号成立。华美饭店于台 东一路 68 号成立。'
              ],
              img: ''
            }
          ],
          heats: [],
          markers: [],
          aside: '到日本第二次占领时期已经成为青岛的商业副中心'
        },
        {
          label: '台东大商圈',
          lNodes: [
            { label: '改革开放后1978-至今', select: true },
            { label: '台东步行街形成' },
            { label: '利群、万达等商场入驻' },
            { label: '台东成为青岛的一大商圈' },
            { label: '登州路啤酒街形成' }
          ],
          rNodes: [
            {
              title: '利群百货',
              subTitles: [
                '1988 年，全市首家股份制企业——利群百货股份有限公 司在台东成立',
                '1994 年，青岛市最大的现代化综合性商业设施——青岛利群商厦在台东开业'
              ],
              img: '/images/components/ov/dh/4-1.png'
            },
            {
              title: '台东邮电局',
              subTitles: ['1995 年，青岛市民心中的地理中心点：台东邮电局'],
              img: ''
            },
            {
              title: '台东三路步行街',
              subTitles: [
                '2004 年，台东三路步行街开始壁画创作，成为青岛最著名的步行街'
              ],
              img: '/images/components/ov/dh/4-2.png'
            }
          ],
          heats: [],
          markers: [],
          aside: '解放后，经历一系列摸索后，最终成就了今天的大商圈'
        }
      ]
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await fetchData('dev_history')
      if (resultCode === '200' && resultData) {
        let result = resultData.content
        result.reverse().map(item => {
          this.list.map(item2 => {
            if (item.title == item2.label) {
              // 点位
              let markers = []
              item.position.map((postion, i) => {
                let color = ''
                let iconW = ''
                let iconH = ''
                if (postion.num == 1) {
                  color = 'blue'
                  iconW = 50
                  iconH = 23
                } else if (postion.num == 2) {
                  color = 'orange'
                  iconW = 36
                  iconH = 16
                } else if (postion.num == 3) {
                  color = 'green'
                  iconW = 44
                  iconH = 18
                } else if (postion.num == 4) {
                  color = 'red'
                  iconW = 60
                  iconH = 30
                }
                markers.push({
                  name: postion.title,
                  lnglat:
                    postion.lng && postion.lat
                      ? [postion.lng, postion.lat]
                      : [],
                  img: postion.img.length ? postion.img[0].url : '',
                  type: postion.num,
                  color,
                  icon: {
                    url: `/images/map/ov/dh/${postion.num}.png`,
                    width: iconW,
                    height: iconH
                  }
                })
              })
              item2.markers = markers

              // 热力图
              let heats = []
              item.therm_chart.map(heat => {
                if (heat.lng && heat.lat) {
                  heats.push({
                    name: heat.title,
                    lnglat: [heat.lng, heat.lat],
                    count: heat.num
                  })
                }
              })
              item2.heats = heats
            }
          })
        })
        console.log(this.list[this.currIndex])
        this.$eventBus.$emit('ov-dh-node', this.list[this.currIndex])
        this.$eventBus.$emit('ov-dh-node-loadMarker', this.list[this.currIndex])
        this.$eventBus.$emit(
          'ov-dh-node-aside',
          this.list[this.currIndex].aside
        )
      }
    },
    handleNode(index) {
      this.currIndex = index
      let item = this.list[index]
      this.$eventBus.$emit('ov-dh-node', item)
      this.$eventBus.$emit('ov-dh-node-updateMarker', item)
      this.$eventBus.$emit('ov-dh-node-aside', item.aside)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: calc(100% - 50px);
  @include flex(column);
  padding: 28px 60px 22px;
  overflow: hidden;
  .node-box {
    @include flex();
    position: relative;
    .node-icon {
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 50%;
      margin-top: 8px;
      margin-left: 1px;
      // z-index: 1;
    }
    .active-icon {
      background: linear-gradient(0deg, #f8ed41 0%, #e4951e 100%);
    }
    .node-icon::after {
      content: '';
      position: absolute;
      top: 24px;
      left: 8px;
      width: 1px;
      height: 100%;
      background: rgba($color: #fff, $alpha: 0.8);
      // z-index: 0;
    }
  }
  .text-box {
    @include flex(column);
    margin-left: 32px;
    cursor: pointer;
  }
  .t-n-box {
    @include flex(column);
    margin: 15px 0;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .active-title,
  .active-node {
    color: #ef9c00;
  }
  .node {
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  .node:last-child {
    margin-bottom: 0;
  }
}
</style>
