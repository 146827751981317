<template>
  <div class="box">
    <span>{{ context }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      context: ''
    }
  },
  mounted() {
    this.$eventBus.$off('ov-dh-node-aside')
    this.$eventBus.$on('ov-dh-node-aside', context => {
      console.log(context)
      this.showText(context)
    })
  },
  methods: {
    showText(text) {
      this.context = ''
      var speed = 10 //定时的速度
      var count = 1
      clearTimeout(this.timer)
      this.context = this.changeContent(text, speed, count)
    },
    // 文字逐个出现  text:文字   speed 定时的速度
    changeContent(text, speed, count) {
      var num = count
      const _this = this
      this.context = text.substring(0, num)
      num++
      if (num < text.length + 1) {
        this.timer = setTimeout(() => {
          _this.changeContent(text, speed, num)
        }, speed)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  position: absolute;
  width: 95%;
  bottom: 10px;
  @include flex();
  align-items: center;
  justify-content: center;
  span {
    font-size: 31px;
    background-color: rgba($color: #000000, $alpha: 0.4);
    padding: 10px;
  }
}
</style>
