<template>
  <div class="container">
    <Aside />
  </div>
</template>

<script>
import Aside from './components/aside'

export default {
  components: { Aside }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  position: relative;
}
</style>
